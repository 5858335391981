import c0 from './accessory-menu_controller';
import c1 from './audio-player_controller';
import c2 from './billingReminders_controller';
import c3 from './cards_controller';
import c4 from './comments_controller';
import c5 from './dailyStats_controller';
import c6 from './deposits_controller';
import c7 from './filepond-cohort-post-media_controller';
import c8 from './filepond-curriculum-article-audio_controller';
import c9 from './group_controller';
import c10 from './hello_controller';
import c11 from './ingredients_controller';
import c12 from './journey-phases_controller';
import c13 from './keyLifts_controller';
import c14 from './meal-ingredients_controller';
import c15 from './ownership_controller';
import c16 from './questionnaires-modal_controller';
import c17 from './questionnaires_controller';
import c18 from './reminders_controller';
import c19 from './search_controller';
import c20 from './sortableAnswers_controller';
import c21 from './subscriptionUpgradeReminders_controller';
import c22 from './subscriptions_controller';
import c23 from './ui-elements_controller';
import c24 from './video_controller';
import c25 from './weight-ranges_controller';
export const definitions = [
	{identifier: 'accessory-menu', controllerConstructor: c0},
	{identifier: 'audio-player', controllerConstructor: c1},
	{identifier: 'billingReminders', controllerConstructor: c2},
	{identifier: 'cards', controllerConstructor: c3},
	{identifier: 'comments', controllerConstructor: c4},
	{identifier: 'dailyStats', controllerConstructor: c5},
	{identifier: 'deposits', controllerConstructor: c6},
	{identifier: 'filepond-cohort-post-media', controllerConstructor: c7},
	{identifier: 'filepond-curriculum-article-audio', controllerConstructor: c8},
	{identifier: 'group', controllerConstructor: c9},
	{identifier: 'hello', controllerConstructor: c10},
	{identifier: 'ingredients', controllerConstructor: c11},
	{identifier: 'journey-phases', controllerConstructor: c12},
	{identifier: 'keyLifts', controllerConstructor: c13},
	{identifier: 'meal-ingredients', controllerConstructor: c14},
	{identifier: 'ownership', controllerConstructor: c15},
	{identifier: 'questionnaires-modal', controllerConstructor: c16},
	{identifier: 'questionnaires', controllerConstructor: c17},
	{identifier: 'reminders', controllerConstructor: c18},
	{identifier: 'search', controllerConstructor: c19},
	{identifier: 'sortableAnswers', controllerConstructor: c20},
	{identifier: 'subscriptionUpgradeReminders', controllerConstructor: c21},
	{identifier: 'subscriptions', controllerConstructor: c22},
	{identifier: 'ui-elements', controllerConstructor: c23},
	{identifier: 'video', controllerConstructor: c24},
	{identifier: 'weight-ranges', controllerConstructor: c25},
];
