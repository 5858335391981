import { Controller } from "stimulus"
import Plyr from 'plyr';
import Hls from 'hls.js'

export default class extends Controller {
  static targets = ['audioPlayer']

  connect() {
    if (Hls.isSupported()) {
      const hls = new Hls({
          withCredentials: false
      });

      hls.loadSource(this.audioPlayerTarget.dataset.url);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
          this.player = new Plyr(this.audioPlayerTarget, {
            crossorigin: 'anonymous',
          });
      });

      hls.attachMedia(this.audioPlayerTarget);
    }
  }
}
