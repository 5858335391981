import Chart from 'chart.js/auto';

document.addEventListener("turbo:load", function(e) {
  window.renderChart = function(chart){
    if(!chart){
      return
    }
    const ctx = chart.getContext('2d');

    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
    gradient.addColorStop(0, 'rgba(1, 194, 190, 0.5)');
    gradient.addColorStop(0.95, 'rgba(1, 194, 190, 0)');

    const labels = ctx.canvas.dataset.labels.split(",");
    const dataValues = ctx.canvas.dataset.data.split(",").map(function(item) {
      return item.trim();
    });
    const data = dataValues.map(item => item.length === 0 ? null : item);
    // console.log('data', data);
    const unit = ctx.canvas.dataset.unit;
    const tooltips = typeof ctx.canvas.dataset.tooltip !== 'undefined' ? ctx.canvas.dataset.tooltip.split(",") : Array(data.length).fill('');
    const tooltipUnit = ctx.canvas.dataset.tooltipunit || unit;
    const tooltipLabel = ctx.canvas.dataset.tooltiplabel;
    let yMin = parseInt(ctx.canvas.dataset.min);
    const yMax = parseInt(ctx.canvas.dataset.max) == 0 ? 100 : parseInt(ctx.canvas.dataset.max) + 2;
    if(yMin !== 0){
      yMin = yMin - 2;
    }
    const stepSize = (yMax - yMin) / 4;
    const trendStart = parseInt(ctx.canvas.dataset.trendstart);
    const trendEnd = parseInt(ctx.canvas.dataset.trendend);
    const trendIncrements = ((trendEnd - trendStart) / labels.length);
    const trend = [...Array(labels.length + 1)].map((x, y) => trendStart + trendIncrements * y);

    const chartOptions = {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          data: data,
          borderColor: '#00DAD5',
          tooltips: tooltips,
          tooltipLabel: tooltipLabel
        },
        {
          data: trend,
          borderColor: 'rgba(0, 59, 119, 0.2)',
          borderDash: [6,6],
          backgroundColor: 'transparent',
          pointRadius: 0,
          pointHitRadius: 0,
          borderWidth: 2,
        }]
      },
      options: {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
        elements: {
          point: {
              radius: 5,
              hoverRadius: 7,
              borderWidth: 3,
              hoverBorderWidth: 3,
              hitRadius: 15,
              backgroundColor: '#fff'
          },
          line: {
            borderWidth: 3,
            fill: true,
            backgroundColor: gradient,
          }
        },
        scales: {
          xAxis: {
            grid: {
              lineWidth: 0,
              drawBorder: false,
              tickLength: 20,
              tickColor: "transparent"
            },
            ticks: {
              color: "#8DAED1",
              font: {
                size: 10
              }
            }
          },
          yAxis: {
            min: yMin,
            max: yMax,
            grid: {
              color: "#E9EEF3",
              drawBorder: false,
              tickLength: 10,
              tickColor: "transparent"
            },
            ticks: {
              color: "#8DAED1",
              font: {
                size: 10
              },
              stepSize: stepSize,
              callback: function(value, index, values) {
                return value + "" + unit;
              }
            }
          }
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            backgroundColor: '#333',
            titleAlign: 'center',
            bodyAlign: 'center',
            padding: 8,
            caretPadding: 10,
            displayColors: false,
            animation: {
              duration: 0
            },
            callbacks: {
              label: function(context) {
                var label = "";
                if (context.dataset.tooltipLabel) {
                  label += '\n' + context.dataset.tooltipLabel;
                }
                if (context.parsed.y !== null) {
                  if (!context.dataset.tooltipLabel) {
                    label += "~";
                  }
                  label += context.parsed.y;
                }
                if (!context.dataset.tooltipLabel) {
                  label += tooltipUnit;
                }
                // console.log('tooltips', context.dataset.tooltips, context.dataIndex);
                // console.log('tooltip', context.dataset.tooltips[context.dataIndex], context.dataset.tooltips[context.dataIndex] != '');
                if(context.dataset.tooltips[context.dataIndex] != '') {
                  label += ' x '+ context.dataset.tooltips[context.dataIndex]
                }
                return label;
              }
            }
          },
        },
      }
    };
    return new Chart(ctx, chartOptions);
  }
  window.weightStatChart = renderChart(document.getElementById(`weight_chart`));
  window.waistStatChart = renderChart(document.getElementById(`waist_chart`));
  const charts = document.getElementsByClassName("key_lift_chart");
  Array.from(charts).forEach((el) => {
    // console.log('elr', el.id);
    window[el.id] = renderChart(el)
  })
  // window.keyLiftStatChart = renderChart('key_lift');
})