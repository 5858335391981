import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['editTrackLift']
  connect() {
  }

  clicked(e){
    e.preventDefault();
    var targetdiv = $(this.editTrackLiftTarget).parent().parent().next('div.edit-track-lift');
    targetdiv.removeClass("hidden");
    $(".submitted").addClass("hidden");
  }
}