import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['topEnd', 'fivePercentBtn']
  connect() {
    //console.log('Target Weight Range Controller');
  }

  calFivePercent(e){
    e.preventDefault();
    const lowEnd = $(this.fivePercentBtnTarget).parent().parent().find('.low-end');
    const topEnd = $(this.fivePercentBtnTarget).parent().parent().find('.top-end');
    const lowEndVal = Number.parseInt($(lowEnd).val());

    if(lowEndVal){
      const percentage = Number.parseFloat((lowEndVal * 5) / 100) ;
      const fivePercentVal = lowEndVal + percentage ;
      $(topEnd).val(Math.round(fivePercentVal));
    }
  }
}
