import { Controller } from "@hotwired/stimulus";
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = ['answer', 'answerInput'];
  connect() {
    console.log('Do what you want here.')
    let _this = this;
    let defaultOptions = {
      animation: 500,
      dataIdAttr: 'data-id',
      onChange: function(event){
        _this.answerInputTarget.value = _this.sortable.toArray();
        _this.enableSubmitBtn();
      }
    }
    this.sortable = Sortable.create(this.element, defaultOptions)
    this.initSort();
  }

  initSort() {
    let orderedId = this.answerInputTarget.value;
    if (orderedId.length > 0){
      this.sortable.sort(orderedId.split(','));
      this.enableSubmitBtn();
    }
  }
  disableSubmitBtn(){
    $('#response-submit-btn').addClass('disabled');
  }

  enableSubmitBtn(){
    $('#response-submit-btn').removeClass('disabled');
  }
}