document.addEventListener("turbo:load", function(e) {
  var currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);


  var startDate = new Date();
  startDate.setMonth(currentDate.getMonth() - 1);
 
  console.log(startDate);

  $('.overview-export-date').flatpickr({
     minDate: startDate,
     maxDate: "today"
  });

  $('#overview-export-form').submit(function(e){
    checkExportCSVStatus();
  });

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    
    return null;
  }
   
  function checkExportCSVStatus(){
    var attempts = 70;
    var csvDownloaderStatusTimer = setInterval(function() {
      var cookieVal = getCookie('csv_downloaded');
     
      attempts--;
     
      if (cookieVal == 'true' || attempts == 0){
        console.log(cookieVal + "..." + attempts);
        window.clearInterval(csvDownloaderStatusTimer);
        $('#loader').addClass('hidden');
        $('.export_csv_btn').removeAttr('disabled');
        $('.export_csv_btn').removeClass('disabled');
      }
    }, 1000); 
  }
});