import Rails from "@rails/ujs"
import {Spinner} from 'spin.js'

$(document).on('turbo:load', function() {

    // User menu
    var userMenuOpen = false;
    $("#user-menu-toggle").click(function() {
        userMenuOpen = !userMenuOpen;
        $("#user-menu").removeClass();
        if (userMenuOpen) {
            $("#user-menu").addClass("transition ease-out duration-200 transform opacity-100 scale-100");
            $("#user-menu-toggle .fa-chevron-down").css({ 'transform': 'rotate(180deg)' });
        } else {
            $("#user-menu").addClass("transition ease-in duration-75 transform opacity-0 scale-95 pointer-events-none");
            $("#user-menu-toggle .fa-chevron-down").css({ 'transform': 'rotate(0deg)' });
        }
    });
    // TODO: close user menu when clicking away

    // Mobile menu
    $("#mobile-menu-toggle").click(function() {
        $("body").toggleClass("overflow-hidden");
        $("#sidebar").toggleClass("relative fixed w-16 w-full bottom-0 lg:bg-transparent bg-blue-700");
        $("#page-wrapper", ".logo-image").toggleClass("hidden");
        $("#sidebar-search, #sidebar-items, #sidebar-footer").toggleClass("hidden");
        $("#mobile-menu-toggle .icon div").toggleClass("hidden block");
        $("#sidebar .logo-image").toggleClass("hidden");
    });

    // Admin Tools Visibility Toggle
    $("#admin-tools-visibility").click(function(e) {
        e.preventDefault();
        toggleAdminToolsVisibility();
    });

    function toggleAdminToolsVisibility() {
        var toggleIconOn = $("#admin-tools-visibility .fa-toggle-on");
        var toggleIconOff = $("#admin-tools-visibility .fa-toggle-off");
        toggleIconOn.toggleClass("hidden");
        toggleIconOff.toggleClass("hidden");
        $("body").toggleClass("tools-hidden");
    }

    if ($('.admin-block').length > 0) {
        $("#admin-tools-visibility").parent().parent().removeClass('hidden');
    }

    // Prepare spinner
    var spinnerOptions = {
      lines: 8, // The number of lines to draw
      length: 7, // The length of each line
      width: 4, // The line thickness
      radius: 7, // The radius of the inner circle
      scale: 1, // Scales overall size of the spinner
      corners: 1, // Corner roundness (0..1)
      speed: 1, // Rounds per second
      rotate: 0, // The rotation offset
      animation: 'spinner-line-fade-default', // The CSS animation name for the lines
      direction: 1, // 1: clockwise, -1: counterclockwise
      color: '#ffffff', // CSS color or array of colors
      fadeColor: 'transparent', // CSS color or array of colors
      top: '50%', // Top position relative to parent
      left: '50%', // Left position relative to parent
      shadow: '0 0 16px rgba(0,0,0,0.75)', // Box-shadow for the lines
      zIndex: 2000000000, // The z-index (defaults to 2e9)
      className: 'spinjs', // The CSS class to assign to the spinner
      position: 'absolute', // Element positioning
    };
    var spinner = new Spinner(spinnerOptions).spin();
    $('#loader').append(spinner.el);

    // Show spinner on any form submit
    $('form').submit(function(res) {
        $('#loader').removeClass('hidden');
        console.log('form submit res', res);
    });

    $(document).on("ajax:error", "#new_post, #new_comment", function(response){
      console.log('form error res', response);
      $('#loader').addClass('hidden');
      alert("Your message couldn't be sent, please check your connection and try again later.");
      return
    })

    // Reading time indicator
    if ($('.r4-readtime-item').length) {
        const win = $(window);
        const doc = $(document);
        const progressBar = $('.r4-readtime-item');
        const setValue = () => win.scrollTop();
        const setPercent = () => win.scrollTop() / (doc.height() - win.height()) * 100;

        progressBar.css({ width: setPercent() + '%' });

        doc.on('scroll', () => {
            progressBar.css({ width: setPercent() + '%' });
        });

        win.on('resize', () => {
            progressBar.css({ width: setPercent() + '%' });
        });
    }

    // Interactive table of contents
    if ($('.r4-stickymenu-content').length) {
        var asidelist = [],
            stickymenu = $('.r4-stickymenu'),
            stickyforh1 = true;

        const win = $(window);
        const doc = $(document);
        var top = 0;

        function aside(asidelist, stickymenu, stickyforh1) {

            // LIST ARRAY
            if ($('.r4-stickymenu-content h2').length > 1) {

                stickyforh1 = false;
                $('.r4-stickymenu-content h2').each(function(index) {

                    var el = $(this),
                        text = el.text(),
                        offset = el.offset().top,
                        liclass = 'r4-stickymenu-li';

                    if (index == 0) {
                        liclass = 'r4-stickymenu-li r4-stickymenu-li-act';
                    }
                    stickymenu.append('<li class="' + liclass + '"><a href="#" data-id="' + index + '" data-offset="' + offset + '" class="r4-stickymenu-a">' + text + '</a></li>');

                    asidelist.push(offset);
                });

            } else {
                $('.r4-stickymenu-content h1').each(function(index) {
                    var el = $(this),
                        text = el.text(),
                        offset = el.offset().top,
                        liclass = 'r4-stickymenu-li';

                    if (index == 0) {
                        liclass = 'r4-stickymenu-li r4-stickymenu-li-act';
                    }
                    stickymenu.append('<li class="' + liclass + '"><a href="#" data-id="' + index + '" data-offset="' + offset + '" class="r4-stickymenu-a">' + text + '</a></li>');

                    asidelist.push(offset);
                });
            }

            // NAV LINKs
            $('.r4-stickymenu li').each(function(index) {
                var li = $(this),
                    btn = li.find('a');

                btn.on('click', function(e) {
                    e.preventDefault();

                    var a = $(this),
                        id = a.data('id'),
                        offset = a.data('offset'),
                        gotoli = $('.r4-stickymenu li:eq(' + id + ')'),
                        goto = $('.r4-stickymenu-content h1:eq(' + id + ')');

                    if (!stickyforh1) {
                        goto = $('.r4-stickymenu-content h2:eq(' + id + ')')
                    }
                    $('html, body').animate({ scrollTop: offset - 40 + 'px' }, 500);
                });
            });
        }

        aside(asidelist, stickymenu, stickyforh1);

        // RESIZE
        win.on('resize', () => {
            var w = $(this).width();

            if (w >= 768) {
                stickymenu.html('');
                asidelist = [];
                aside(asidelist, stickymenu, stickyforh1);
            }

        });

        // SCROLL
        doc.on('scroll', () => {
            top = win.scrollTop();

            $.each(asidelist, function(index, value) {
                if ((top + 100) > value) {
                    $('.r4-stickymenu .r4-stickymenu-li-act').removeClass('r4-stickymenu-li-act');
                    $('.r4-stickymenu li:eq(' + index + ')').addClass('r4-stickymenu-li-act');
                }

            });

        });

    }


    // Switch button styling
    var normalClasses = 'defaulted';
    var highlightedClasses = 'highlighted';
    var toggleClasses = normalClasses + ' ' + highlightedClasses;

    // Replace dropdown with individual select buttons
    window.select_btn_to_tabs = function() {
        $('.button-select, .button-select-group').each(function() {
            var select = $(this).find('select');
            select.hide();
            // if($(this).find('.selector').length === 0){
            var selectorWrapper = $('<div/>').addClass('selector');
            selectorWrapper.insertBefore(select);

            var options = $(this).find('option');
            options.each(function() {
                var option = $(this)
                var text = option.text();
                var value = option.val();
                var data = option.data();
                var is_selected = value == select.val()
                if (text.length != 0) {
                    if(data.step){
                        var optionButton = $('<button data-action="click->questionnaires#toggleSubmitBtnOnSingleSelect" data-questionnaires-target="singleChoiceSelect" />').addClass(normalClasses).text(text);
                    }else{
                        var optionButton = $('<button data-action="click->dailyStats#inputChanged" data-dailyStats-target="weight" />').addClass(normalClasses).text(text);
                    }
                    optionButton.click(function(e) {
                        e.preventDefault();
                        $(selectorWrapper).find('button.highlighted').toggleClass(toggleClasses);
                        optionButton.toggleClass(toggleClasses);
                        $(select).val(value).change();
                    });
                    if (is_selected) {
                        optionButton.toggleClass(toggleClasses); // highlight existing selection
                    }
                    $(selectorWrapper).append(optionButton);
                }
            });
            // }
        });
    }

    select_btn_to_tabs();

    if ($('#equipment-selector').length) {
        $('#equipment-selector #where button').click(function(e) {
            e.preventDefault();
            if (!$(this).hasClass('highlighted')) {
                $(this).parent().parent().find('button.highlighted').toggleClass(toggleClasses);
                $(this).toggleClass(toggleClasses);

                if ($(this).attr('id') == "at-home") {
                    $('#equipment-selector #home').removeClass('hidden');
                    $('#equipment-selector #gym').addClass('hidden');
                }

                if ($(this).attr('id') == "at-gym") {
                    $('#equipment-selector #home').addClass('hidden');
                    $('#equipment-selector #gym').removeClass('hidden');
                }

                $('#options').slideDown(200);
            }
            updateSelection();
        });


        $('#equipment-selector #options button').click(function(e) {
            e.preventDefault();
            $(this).toggleClass(toggleClasses);

            // Show or hide bench option
            if (selected("#home-dumbbells")) {
                $('#home-bench').show();
            } else {
                $('#home-bench').hide();
            }

            // Show or hide bodyweight option
            if (selected("#home-dumbbells") || selected("#home-bands")) {
                $('#home-bodyweight').hide();
            } else {
                $('#home-bodyweight').show();
            }

            updateSelection();
        });

        function updateSelection() {
            var canPass = false;
            var selection = 0;

            if (selected('#at-home')) {
                if (selected('#home-dumbbells') && selected('#home-bands') && selected('#home-bench')) {
                    selection = 4;
                    canPass = true;
                } else if (selected('#home-dumbbells') && selected('#home-bench')) {
                    selection = 5;
                    canPass = true;
                } else if (selected('#home-dumbbells') && selected('#home-bands')) {
                    selection = 6;
                    canPass = true;
                } else if (selected('#home-dumbbells')) {
                    selection = 7;
                    canPass = true;
                } else if (selected('#home-bands')) {
                    selection = 8;
                    canPass = true;
                } else if (selected('#home-bodyweight')) {
                    selection = 9;
                    canPass = true;
                }

            } else if (selected('#at-gym')) {
                if (selected('#gym-free-weights') && selected('#gym-machines')) {
                    selection = 1;
                    canPass = true;
                } else if (selected('#gym-free-weights')) {
                    selection = 2;
                    canPass = true;
                } else if (selected('#gym-machines')) {
                    selection = 3;
                    canPass = true;
                }
            }

            $('#result').text(selection);
            $('#profile_equipment').prop('selectedIndex', selection).change();

            if (canPass) {
                $('#proceed-button').slideDown(200);
            } else {
                $('#proceed-button').slideUp(200);
            }
        }

        function selected(element) {
            if ($(element).hasClass('highlighted')) {
                return true;
            } else {
                return false;
            }
        }
    }

    // Tabs component
    $(document).on('click', '.tabs a', function(e) {
        // $('.tabs a').click( function(e) {
        e.preventDefault();
        $(this).closest('.tabs').find('a').removeClass('selected');
        $(this).addClass('selected');
        $(this).closest('.tabs').parent().find('.tab-contents > div').removeClass('active');
        var target = $(this).attr('href');
        $(target).addClass('active');
    });

    // Page control
    $('.page-control a[data-direction]').click(function(e) {
        e.preventDefault();
        var direction = $(this).attr("data-direction");
        var currentPageElement = $('.page-control .slides > div:not(.hidden)');
        var currentPageIndicator = $('.page-control .indicators > div:not(.opacity-50)');
        currentPageElement.addClass('hidden');
        currentPageIndicator.addClass('opacity-50');
        if (direction == "prev") {
            currentPageElement.prev().removeClass('hidden');
            currentPageIndicator.prev().removeClass('opacity-50');
        } else if (direction == "next") {
            currentPageElement.next().removeClass('hidden');
            currentPageIndicator.next().removeClass('opacity-50');
        }
    });
    $('.page-control .slides .h-0.overflow-hidden').removeClass('h-0 overflow-hidden').addClass('hidden');

    // Unclamping (for `read more` buttons)
    $('a.unclamp').click(function(e) {
        e.preventDefault();
        $(this).parent().find('.clamped').addClass('hidden');
        $(this).parent().find('.unclamped').removeClass('hidden');
        $(this).remove();
    });

    // Feed composer
    $(document).on('focus', 'textarea.feed-post-composer.collapsed, div.feed-post-composer.collapsed', function() {
        $(this).removeClass('collapsed h-10');
        $('#feed-post-courses').removeClass('hidden');
        $('#feed-post-controls').removeClass('hidden');
    });

    $(document).on('click', '#feed-post-accessories a', function(e) {
        e.preventDefault();
        $(this).addClass('pointer-events-none opacity-20');
        var item = $(this).attr('href');
        $(item).removeClass('hidden');
    });

    // Stage selector
    $('.stage-selector button').click(function(e) {
        e.preventDefault();
        if (!$(this).hasClass('selected')) {
            $('.stage-submit-button').removeClass('hidden');
            $('.stage-selector button.selected').removeClass('selected');
            $(this).addClass('selected');
            $('select#user_stage').val($(this).data('target-stage'));
        }
    });

    // Showing more comments in threads for example
    $(document).on('click','a.unhide-neighbors',function(e) {
        e.preventDefault();
        $(this).parent().find('.hidden').removeClass('hidden');
        $(this).remove();
        $('.accessory-menu').removeClass('open').addClass('hidden');
    });

    // Unhide element of given id
    $('[data-unhide]').click(function(e) {
        e.preventDefault();

        var id = $(this).attr('data-unhide');
        var hiddenBlock = $('#' + id);
        if (hiddenBlock.is(":hidden")) {
            hiddenBlock.slideDown();
        } else {
            hiddenBlock.slideUp();
        }

        if ($(this).hasClass('prevent-rotation')) {
            $(this).find('i, svg').toggleClass('opacity-50');
        } else {
            $(this).find('i, svg').toggleClass('transform rotate-90');
        }
    });

    // Scroll to focus item
    function focusScroll() {
        // TODO: potentially also account for vertical focus if/when needed
        $('[data-scroll-focus]').each( function() {
            const elementInFocus = $(this);
            const horizontalScroller = elementInFocus.closest('.overflow-x-scroll');
            const horizontalOffset = elementInFocus.position().left + horizontalScroller.scrollLeft();
            horizontalScroller.scrollLeft(horizontalOffset);
        });
    }
    // Focus on load
    focusScroll();
    // Focus on resize
    $(window).resize(function() {
        focusScroll();
    });

    // Copy string to clipboard
    $('[data-copy]').click(function(e) {
        e.preventDefault();
        var string = $(this).attr('data-copy');
        navigator.clipboard.writeText(string);
        // TODO: class switching may not work with svg icons
        $(this).find(".fa-copy").removeClass("fa-copy").addClass("fa-check");
    });

    // Convert any case, incl. all caps, to normal capitalized case.
    // Protects client from themselves.
    $('.uppercase-to-capitalize').text(function(index, currentText) {
        return currentText.toLowerCase();
    }).addClass('capitalize');

    // Daily/Weekly Check In interactions
    $(document).on('click', '.reset_stat', function(event) {
        event.preventDefault();
        const PARENT = $(this).next();
        $(PARENT).find('select').val('');
        $(PARENT).find('button.highlighted').toggleClass(toggleClasses);
    })

    $(document).on('click', '.reset_weekly_stat', function(event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        $('#weekly_stat_step').val('');
        $('#weekly_stat_reset').val('1');
        const PARENT = $(this).closest('.step');
        $(PARENT).find('select').val('').change();
        $(PARENT).find('button.highlighted').toggleClass(toggleClasses);
        $(PARENT).find('.question-wrapper').removeClass('answered');
    })

    $(document).on('change', '.weekly_stat_select', function() {
        // console.log('step', $(this).data('step'));
        const step = $(this).data('step');
        if ($(this).val() == '') {
            $('#step_' + step + ' .reset_weekly_stat').addClass('disabled');
        } else {
            $('#step_' + step + ' .reset_weekly_stat').removeClass('disabled');
        }
        $('#weekly_stat_step').val(step);
        $('#step_' + step + ' .question-wrapper').addClass('answered');
        setTimeout(
            function() {
                const form = document.getElementById('weekly_checkin_form');
                Rails.fire(form, 'submit');
            }, 150);
    })

    $(document).on('click', '#btn_save_weekly_waist', function(e) {
        e.preventDefault();
        const form = document.getElementById('weekly_checkin_form');
        Rails.fire(form, 'submit');
    })

    // Toggle daily stat row
    toggledailyStatsRow();

    // Filters submission triggers
    $('.filters-bar form').each(function() {
        $(this).find('input').keypress(function(e) {
            // Enter pressed?
            if (e.which == 10 || e.which == 13) {
                this.form.submit();
            }
        });
        $(this).find('select, input[type=checkbox]').change(function() {
            this.form.submit();
        });
    });

    // Apply icons to inputs with help of Font Awesome.
    // This replaces script that converts symbols in
    // css pseudo-elements, which are extremely taxing
    // on performance.
    function applyInputIcon(inputType, iconName) {
        $(".filters-bar .input." + inputType).prepend("<i class='fas fa-" + iconName + "'></i>");
    }
    applyInputIcon("string", "magnifying-glass");
    applyInputIcon("email", "magnifying-glass");
    applyInputIcon("select", "chevron-down");

    // Show instructions or send button for comments when in focus
    // $('[data-comments-target] .textarea').on("focus", function() {
    //     $(this).removeClass('caret-transparent').addClass('caret-gray-900');
    //     $(this).parent().parent().find('.key-instructions').slideDown(200);
    //     $(this).parent().parent().find('.send').removeClass("opacity-0 scale-0 pointer-events-none");
    // });

    // $('[data-comments-target] .textarea').on("blur", function(event) {
    //     console.log('event', event, event.target);
    //     $(this).parent().parent().find('.key-instructions').slideUp(200);
    //     $(this).parent().parent().find('.send').addClass("opacity-0 scale-0 pointer-events-none");
    // });
});

window.toggledailyStatsRow = function() {
    $('a.daily_stat_row_toggle:not(.pointer-events-none)').click(function(e) {
        e.preventDefault();
        var animationDuration = 150;
        if ($(this).hasClass('expanded')) {
            $(this).removeClass('expanded');
            $(this).find('.fas').removeClass('rotate-90');
            $(this).parent().parent().find('.quick-answers').fadeIn(animationDuration);
            $(this).parent().parent().parent().find('.quick-answers-extended').slideUp(animationDuration);
        } else {
            $(this).addClass('expanded');
            $(this).find('.fas').addClass('rotate-90');
            $(this).parent().parent().find('.quick-answers').fadeOut(animationDuration);
            $(this).parent().parent().parent().find('.quick-answers-extended').slideDown(animationDuration);
        }
    });
}