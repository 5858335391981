import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['radio', 'text', 'singleChoiceSelect', 'sortable']
  connect() {
    select_btn_to_tabs();
    const singleSelectedEle = $('.singleChoiceSelect').find(":selected");
    const multipleselectedEle = $(".multipleChoiceSelect:checked");
    const longAnswerEle = $(".long-answer");
    const radioEle = $(".radioBtn:checked")

    if(singleSelectedEle.length > 0){
      if(!$(singleSelectedEle).val()){
        this.disableSubmitBtn();
        return;
      }
    }


    if(radioEle.length > 0 || (longAnswerEle.length > 0 && $(longAnswerEle).val())){
      this.enableSubmitBtn();
      return;
    }else{
      this.disableSubmitBtn();
      return;
    }
  }

  toggleSubmitBtnOnSingleSelect(e){
    const singleSelectedEle = $('.singleChoiceSelect').find(":selected")

    if (singleSelectedEle.length > 0) {
      if(!$(singleSelectedEle).val()){
        this.disableSubmitBtn();
        return;
      }
      const answer = $(singleSelectedEle).data().value;
      if(answer == 'Yes'){
        if($(this.textTarget).val().length > 0){
          this.enableSubmitBtn();
        }else{
          this.disableSubmitBtn();
        }
      }else{
        this.enableSubmitBtn();
      }
    }else{
      if($(this.textTarget).val().length > 0){
        this.enableSubmitBtn();
      }else{
        this.disableSubmitBtn();
      }
    }
  }

  toggleSubmitBtnOnMultiSelect(e){
    const multipleselectedEle = $(".multipleChoiceSelect:checked");

    if (multipleselectedEle.length > 0) {
      this.enableSubmitBtn();
      return;
    }else{
      this.disableSubmitBtn();
    }
  }

  toggleSubmitBtnOnRadioSelect(e){
    const radioEle = $(".radioBtn:checked")

    if (radioEle.length > 0) {
      this.enableSubmitBtn();
      return;
    }else{
      this.disableSubmitBtn();
    }
  }

  disableSubmitBtn(){
    $('#response-submit-btn').addClass('disabled');
  }

  enableSubmitBtn(){
    $('#response-submit-btn').removeClass('disabled');
  }
}