import { Controller } from 'stimulus';
import Rails from "@rails/ujs";
export default class extends Controller {
  static targets = ['dismiss']
  connect() {
    console.log('billing reminders');
    this.periodId = this.dismissTarget.getAttribute('data-period');
  }

  dismiss(e){
    e.preventDefault();
    console.log('dismiss billing reminder');
    const data = { period_id: this.periodId };
    Rails.ajax({
        type: "post",
        url: this.dismissTarget.dataset.url,
        beforeSend(xhr, options) {
          xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
          // Workaround: add options.data late to avoid Content-Type header to already being set in stone
          // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
          options.data = JSON.stringify(data)
          return true
        },
        dataType: 'json',
        success: function(data, status, xhr) {
          document.getElementById('billing-reminder-container').classList.add('hidden');
        }
      })
  }
}