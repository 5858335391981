import { Controller } from 'stimulus';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ 'cardNumber', 'cardExpiry', 'cardCvc', 'cardNumberError', 'cardExpiryError', 'cardCvcError', 'cardErrors', 'formContainer', 'depositFormContainer', 'priceContainer', 'coupon', 'coursePrice', 'totalPrice', 'existingForm', 'paymentForm', 'frequencyText', 'chooseDifferent', 'couponPreview', 'couponDiscountDetails', 'couponDiscountAmount', 'paidPrice', 'subscriptionInfo', 'confirmCouponPayment', 'formsContainer' ]
  static values = {
    key: String,
    intentUrl: String,
    scheduleUrl: String,
    setupIntentUrl: String,
    upgradeScheduleUrl: String
  }

  connect() {
    this.csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    this.price_id = '';
    this.couponValid = false;
    this.validCouponCode = '';
    this.upgradeMode = false;
    this.totalPriceText = '';
    this.intervalText = '';
    this.cardNumber = '';
    this.stripe = Stripe(this.keyValue);
    const that = this;
    var style = {
      base: {
        fontWeight: 600,
        fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',

        ':focus': {
          color: '#424770',
        },

        '::placeholder': {
          color: '#9BACC8',
        },

        ':focus::placeholder': {
          color: '#CFD7DF',
        },
      },
      invalid: {
        color: '#eb1c26',
        ':focus': {
          color: '#FA755A',
        },
        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    };
    var elements = this.stripe.elements({
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css?family=Quicksand',
        },
      ]
    });

    var elementClasses = {
      focus: 'focus',
      empty: 'empty',
      invalid: 'invalid',
    };

    this.cardNumber = elements.create('cardNumber', {
      style: style,
      showIcon: true,
      iconStyle: 'solid',
      placeholder: 'Card Number',
      classes: elementClasses
    });
    this.cardNumber.mount(this.cardNumberTarget);

    var cardExpiry = elements.create('cardExpiry', {
      style: style,
      placeholder: 'Expiry Date',
      classes: elementClasses,
    });
    cardExpiry.mount(this.cardExpiryTarget);

    var cardCvc = elements.create('cardCvc', {
      style: style,
      placeholder: 'CVC',
      classes: elementClasses,
    });
    cardCvc.mount(this.cardCvcTarget);

    this.cardNumber.on('change', function (event) {
      that.displayError(that.cardNumberErrorTarget, event);
    });
    cardExpiry.on('change', function (event) {
      that.displayError(that.cardExpiryErrorTarget,event);
    });
    cardCvc.on('change', function (event) {
      that.displayError(that.cardCvcErrorTarget, event);
    });
  }

  displayError(elem, event) {
    if (event.error) {
      elem.textContent = event.error.message;
      elem.classList.remove('hidden');
    } else {
      elem.textContent = '';
      elem.classList.add('hidden');
    }
  }

  paymentFormTargetConnected(element){
    const form = this.paymentFormTarget;
    const that = this;
    form.addEventListener('submit', function(event) {
      // console.log('setup form submit');
      event.preventDefault();
      $('#loader').removeClass('hidden');
      $('#card-errors, #card-number-error, #card-expiry-error, #card-cvc-error').html('').addClass('hidden');
      if(that.upgradeMode){
        that.handleUpgradeWithNewCard();
      } else {
        that.handleNewSubscriptionWithNewCard();
      }
    })
  }

  handleNewSubscriptionWithNewCard(){
    const that = this;
    fetch(that.intentUrlValue, {
      method: 'post',
      body: JSON.stringify({
        price_id: that.price_id,
        coupon: that.validCouponCode
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-CSRF-Token': that.csrf
      }
    })
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      //console.log('intent result', result);
      if (result.error) {
        throw result;
      }
      return that.stripe
        .confirmCardPayment(result.intent.client_secret, {
          payment_method: {
            card: that.cardNumber
          }
        })
        .then(function(result) {
          // Handle result.error or result.paymentIntent
          //console.log('confirm payment result', result);
          if (result.error) {
            // Inform the customer that there was an error.
            that.showCardError(result.error.message);
            return false;
          } else{
            // console.log('result setup intent', result);
            that.processPaymentAndScheduleSubscription(result.paymentIntent.payment_method)
          }
        })
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          //console.log('error 200', error);
          $('#loader').addClass('hidden');
          if(error.coupon_invalid){
            alert(error.error.message);
            $("button[type='submit']").removeAttr('disabled');
            window.location.reload();
            return;
          }
          that.showCardError(error.message);
          return false;
        })
    })

    // No more actions required. Provision your service for the user.
    .catch((error) => {
      // An error has happened. Display the failure to the user here.
      // We utilize the HTML element we created.
      //console.log('error 223', error);
      $('#loader').addClass('hidden');
      if(error.coupon_invalid){
        alert(error.error.message);
        $("button[type='submit']").removeAttr('disabled');
        window.location.reload();
        return;
      }
      that.showCardError(error);
      return false;
    })
  }

  handleUpgradeWithNewCard(){
    const that = this;
    fetch(that.setupIntentUrlValue, {
      method: 'post',
      body: JSON.stringify({
        coupon: that.validCouponCode && that.couponValid ? that.validCouponCode : null
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-CSRF-Token': that.csrf
      }
    })
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      //console.log('intent result', result);
      if (result.error) {
        throw result;
      }
      return that.stripe
        .confirmCardSetup(result.intent.client_secret, {
          payment_method: {
            card: that.cardNumber
          }
        })
        .then(function(result) {
          // Handle result.error or result.paymentIntent
          //console.log('confirm payment result', result);
          if (result.error) {
            // Inform the customer that there was an error.
            that.showCardError(result.error.message);
            return false;
          } else{
            // console.log('result setup intent', result);
            that.upgradeSubscriptionSchedule(result.setupIntent.payment_method)
          }
        })
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          //console.log('error 200', error);
          $('#loader').addClass('hidden');
          if(error.coupon_invalid){
            alert(error.error.message);
            $("button[type='submit']").removeAttr('disabled');
            window.location.reload();
            return;
          }
          that.showCardError(error.message);
          return false;
        })
    })

    // No more actions required. Provision your service for the user.
    .catch((error) => {
      // An error has happened. Display the failure to the user here.
      // We utilize the HTML element we created.
      //console.log('error 223', error);
      $('#loader').addClass('hidden');
      if(error.coupon_invalid){
        alert(error.error.message);
        $("button[type='submit']").removeAttr('disabled');
        window.location.reload();
        return;
      }
      that.showCardError(error);
      return false;
    })
  }

  existingFormTargetConnected(){
    const existingForm = this.existingFormTarget;
    const that = this;
    existingForm.addEventListener('submit', function(event) {
      // console.log('existing form submit');
      event.preventDefault();
      $('#loader').removeClass('hidden');
      $('#card-errors, #card-number-error, #card-expiry-error, #card-cvc-error').html('').addClass('hidden');
      if(that.upgradeMode){
        that.handleUpgradeWithExistingCard();
      } else {
        that.handleNewSubscriptionWithExistingCard();
      }
    })
  }

  handleNewSubscriptionWithExistingCard(){
    const that = this;
    fetch(that.intentUrlValue, {
      method: 'post',
      body: JSON.stringify({
        price_id: that.price_id,
        coupon: that.validCouponCode
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-CSRF-Token': that.csrf
      }
    })
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      //console.log('result', result);
      if (result.error) {
        throw result;
      }
      return that.stripe
      .confirmCardPayment(result.intent.client_secret, {
        payment_method: result.payment_method
      })
      .then(function(result) {
        // Handle result.error or result.paymentIntent
        //console.log('result', result);
        if (result.error) {
          // Inform the customer that there was an error.
          showExistingCardError(result.error.message);
          return false;
        } else {
          that.processPaymentAndScheduleSubscription();
        }
      })
      .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          //console.log('error', error);
          showExistingCardError(error.message);
          $('#loader').addClass('hidden');
        })
      })
  }

  handleUpgradeWithExistingCard(){
    const that = this;
    fetch(that.upgradeScheduleUrlValue, {
        method: 'post',
        body: JSON.stringify({
          coupon: that.validCouponCode
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-CSRF-Token': that.csrf
        }
      })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        //console.log('intent result', result);
        if (result.error) {
          throw result;
        }
        return result;
      })
      .then(that.onSubscriptionUpgrade)
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        //console.log('error 200', error);
        $('#loader').addClass('hidden');
        if(error.coupon_invalid){
          alert(error.error.message);
          $("button[type='submit']").removeAttr('disabled');
          window.location.reload();
          return;
        }
        that.showCardError(error.message);
        return false;
      })
  }



  upgradeSubscriptionSchedule(paymentMethodId){
    const that = this;
    return (
      fetch(this.upgradeScheduleUrlValue, {
        method: 'post',
        body: JSON.stringify({
          payment_method_id: paymentMethodId,
          coupon: that.validCouponCode
        }),
        headers: {
          'Content-type': 'application/json',
          'X-CSRF-Token': that.csrf
        }
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          console.log('result', result);
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })

        .then((result) => {
          return {
            paymentMethodId: paymentMethodId,
            subscription: result
          };
        })
        // No more actions required. Provision your service for the user.
        .then(this.onSubscriptionUpgrade)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          this.showCardError(error);
        })
    );
  }

  processPaymentAndScheduleSubscription(paymentMethodId){
    const that = this;
    return (
      fetch(that.scheduleUrlValue, {
        method: 'post',
        body: JSON.stringify({
          payment_method_id: paymentMethodId,
          price_id: that.price_id,
          coupon: that.validCouponCode
        }),
        headers: {
          'Content-type': 'application/json',
          'X-CSRF-Token': that.csrf
        }
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          // console.log('result', result);
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })

        .then((result) => {
          return {
            paymentMethodId: paymentMethodId,
            subscription: result
          };
        })
        // No more actions required. Provision your service for the user.
        .then(this.onSubscriptionCreate)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          this.showCardError(error);
        })
    );
  }

  onSubscriptionCreate() {
    // subscription schedule created
    window.location = '/'
  }

  onSubscriptionUpgrade() {
    // subscription schedule upgraded
    window.location = '/billing'
  }

  selectPrice(event) {
    event.preventDefault();
    event.stopPropagation();
    const target = event.target;
    // console.log('select price', target.dataset);
    this.price_id = target.dataset.price_id;
    this.totalPrice = target.dataset.price_total_text;
    this.intervalText = target.dataset.interval_text;
    this.coursePriceTarget.innerHTML = target.dataset.price_text + target.dataset.interval_text;
    this.totalPriceTarget.innerHTML = target.dataset.price_total_text + target.dataset.interval_text;
    this.totalPriceText = target.dataset.price_total_text;
    this.chooseDifferentTarget.classList.remove('hidden');
    this.frequencyTextTarget.classList.remove('hidden');
    this.priceContainerTarget.classList.add('hidden');
    this.formContainerTarget.classList.remove('hidden');
    this.subscriptionInfoTarget.classList.add('hidden');
    $('.featured-subscription').removeClass('featured-subscription');
  }

  selectDeposit(event){
    // console.log('selectDeposit');
    event.preventDefault();
    event.stopPropagation();
    this.resetPrice(event);
    this.subscriptionInfoTarget.classList.add('hidden');
    this.priceContainerTarget.classList.add('hidden');
    this.formContainerTarget.classList.add('hidden');
    this.depositFormContainerTarget.classList.remove('hidden');
    this.chooseDifferentTargets.forEach(element => {
      element.classList.remove('hidden');
    });
  }

  resetPrice(event) {
    const target = event.target;
    this.price_id = '';
    this.totalPrice = '';
    this.intervalText = '';
    this.coursePriceTarget.innerHTML = '';
    this.totalPriceTarget.innerHTML = '';
    this.frequencyTextTarget.classList.add('hidden');
    this.chooseDifferentTarget.classList.add('hidden');
    this.priceContainerTarget.classList.remove('hidden');
    this.formContainerTarget.classList.add('hidden');
    if(this.hasDepositFormContainerTarget){
      this.depositFormContainerTarget.classList.add('hidden');
    }
    this.couponValid = false;
    this.validCouponCode = '';
    this.couponPreviewTarget.classList.add('hidden');
    this.totalPriceText = '';
    this.subscriptionInfoTarget.classList.remove('hidden');
  }

  upgradePrice(event){
    event.preventDefault();
    event.stopPropagation();
    this.upgradeMode = true;
    const target = event.target;
    // console.log('select price', target.dataset);
    this.price_id = target.dataset.price_id;
    this.totalPriceText = target.dataset.price_total_text_without_coupon;
    this.intervalText = target.dataset.interval_text;
    this.coursePriceTarget.innerHTML = target.dataset.price_text + target.dataset.interval_text;
    this.totalPriceTarget.innerHTML = target.dataset.price_total_text + target.dataset.interval_text;
    this.subscriptionInfoTarget.classList.add('hidden');
    if(target.dataset.coupon.length){
      this.couponPreviewTarget.classList.remove('hidden');
      this.couponValid = true;
      this.validCouponCode = target.dataset.coupon;
      this.couponDiscountDetailsTarget.textContent = target.dataset.coupon_percentage == 'true' ? `${target.dataset.coupon_percentage}% (${target.dataset.coupon})` : `(${target.dataset.coupon})`;
      this.couponDiscountAmountTarget.textContent = `- ${target.dataset.coupon_discount}`;
    }
    $('#paid-price').html(`- ${target.dataset.amount_paid}`);
    this.frequencyTextTarget.classList.add('hidden');
    this.paidPriceTarget.classList.remove('hidden');
    this.priceContainerTarget.classList.add('hidden');
    this.formContainerTarget.classList.remove('hidden');
  }

  validateCoupon(event){
    event.preventDefault();
    event.stopPropagation();
    const couponCode = this.couponTarget.value;
    // console.log('coupon code', couponCode);
    const validateUrl = '/subscription/coupon/validate';
    const that = this;
    if(couponCode.length > 0){
      // console.log('validate coupon');
      $('#loader').removeClass('hidden');
      fetch(validateUrl,
        {
          method: 'post',
          body: JSON.stringify({coupon: couponCode, price_id: this.price_id, upgrade_mode: that.upgradeMode}),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'X-CSRF-Token': that.csrf
          }
      })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        // console.log('result', result);
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          throw result;
        }
        return result;
      })
      .then((res) => {
        $('#loader').addClass('hidden');
        console.log('validate coupon res', res);
        if(res.valid == true){
          that.totalPriceTarget.innerHTML = res.price_total_text + res.interval_text;
          that.couponValid = true;
          that.validCouponCode = couponCode;
          that.couponTarget.value = '';
          that.couponPreviewTarget.classList.remove('hidden');
          that.couponDiscountDetailsTarget.textContent = res.percentage ? ` ${res.value}% (${res.code.toUpperCase()})` : ` (${res.code.toUpperCase()})`;
          that.couponDiscountAmountTarget.textContent = `- ${res.discount} ${res.interval_text}`;
          if(res.total <= 0 && !this.upgradeMode){
            this.confirmCouponPaymentTarget.classList.remove('hidden');
            this.formsContainerTarget.classList.add('hidden');
          }
        } else {
          alert('Discount code is not valid.');
          that.couponPreviewTarget.innerHTML = '';
          that.couponTarget.value = '';
          that.couponValid = false;
          that.validCouponCode = '';
          that.couponPreviewTarget.classList.add('hidden');
          that.validCouponCodeTarget.textContent = '';
          that.couponDiscountDetailsTarget.textContent = '';
          that.couponDiscountAmountTarget.textContent = '';
        }
      })
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        console.log('error', error);
        $('#loader').addClass('hidden');
      })
    }
  }

  confirmPaymentWithCoupon(event){
    event.preventDefault();
    event.stopPropagation();
    const confirmUrl = '/subscription/coupon/confirm';
    const that = this;
    if(that.validCouponCode.length > 0 && !this.upgradeMode){
      console.log('confirm coupon');
      $('#loader').removeClass('hidden');
      fetch(confirmUrl,
        {
          method: 'post',
          body: JSON.stringify({coupon: that.validCouponCode, price_id: this.price_id, upgrade_mode: this.upgradeMode}),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'X-CSRF-Token': that.csrf
          }
      })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        // console.log('result', result);
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          throw result;
        }
        return result;
      })
      .then((res) => {
        $('#loader').addClass('hidden');
        console.log('validate coupon res', res);
        if(res.valid == true){
          window.location = '/';
        }
      })
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        console.log('error', error);
        $('#loader').addClass('hidden');
      })
    }
  }

  showCardError(message){
    //console.log('last card error', message);
    $('#loader').addClass('hidden');
    $("button[type='submit']").removeAttr('disabled');
    this.cardErrorsTarget.classList.remove('hidden');
    this.cardErrorsTarget.innerHTML = message;
  }

  showExistingCardError(message){
    $('#loader').addClass('hidden');
    $("button[type='submit']").removeAttr('disabled');
    $('#existing-card-errors').removeClass('hidden').html(message);
  }
}