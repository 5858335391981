import { Controller } from "stimulus"
import * as FilePond from 'filepond';
import { DirectUpload } from "@rails/activestorage";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import CustomFilePondPluginValidateVideoLength from '../filepond_plugin_video_length.js';
//import FilePondPluginFileEncode from 'filepond-plugin-file-encode';

//import "channels";


FilePond.registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginFilePoster,
  FilePondPluginMediaPreview,
  FilePondPluginFileValidateSize,
  CustomFilePondPluginValidateVideoLength,
  //FilePondPluginFileEncode
);

export default class extends Controller {
  static targets = ["curriculumAudio"]

  connect() {
    this.emtpyAudioValue();
    this.applyFilePond();
  }

  emtpyAudioValue(){
    $('#curriculum-audio').val('');
  }

  isAllowedAudioType(type){
    var audioTypes = ["audio/mp3", "audio/m4a", "audio/mpeg", 'audio/x-m4a']

    // If the element was not found, -1 will be returned.
    if($.inArray(type, audioTypes) !== -1){
      return true;
    }else{
      return false;
    }
  }

  enableSubmit() {
    $('#curriculum-submit-btn').removeAttr('disabled', '').removeClass('disabled').text('Save Article');
  }

  disableSubmit() {
    $('#curriculum-submit-btn').attr('disabled', '').addClass('disabled').text('Hold on...');
  }

  isLoadingFiles(filePond) {
    var isFileLoading = filePond.getFiles().filter(x => x.status !== 5).length !== 0;

    if (isFileLoading) {
      this.disableSubmit();
    } else {
      this.enableSubmit();
    }
  }

  applyFilePond() {
    const ele = this.curriculumAudioTarget;
    const existingAudio = document.querySelector("#existing_curriculum_article_audio");

    var audioDataSet;

    var audioUrl;
    var audioSignedId;
    var audioName;
    var audioSize;
    var audioContentType;
    var audioId;
    var audioBlobId;
    var posterImageUrl;

    if(existingAudio){
      audioDataSet = $("#existing_curriculum_article_audio").data();
    }else{
      audioDataSet = null;
    }

    const filePond = FilePond.create(ele, {
      credits: false,
      acceptedFileTypes: ["audio/mp3", "audio/m4a", "audio/mpeg", 'audio/x-m4a'],
      fileValidateTypeLabelExpectedTypes: 'You can only upload audio.',
      allowAudioPreview: true,
      maxFiles: 1,
      labelTapToUndo: '',
      iconRemove: '<i class="fas fa-trash"></i>',
      stylePanelLayout: 'compact',
      labelIdle: 'Drag & Drop your file or <span class="filepond--label-action"> Browse </span>'
    });


    if (existingAudio) {
      if (audioDataSet) {
        audioUrl = audioDataSet.audioUrl;
        audioSignedId = audioDataSet.audioSignedId;
        audioName = audioDataSet.audioName;
        audioSize = audioDataSet.audioSize;
        audioContentType = audioDataSet.audioContentType;
        audioId = audioDataSet.audioId;
        audioBlobId = audioDataSet.audioBlobId;
        posterImageUrl = audioDataSet.posterImage;

        $('#curriculum-audio').val(audioSignedId);

        filePond.setOptions({
          files: [{
            source: audioSignedId,
            options: {
              type: 'local',
              file: {
                name: audioName,
                size: audioSize,
                type: audioContentType,
                url: audioUrl
              },
              metadata: {
                poster: posterImageUrl
              }
            }
          }],
          onremovefile: (error, file) => {
            // Remove blobs that are uploaded through direct-upload before saving in signedid in db
            fetch(`/remove_blob/${audioBlobId}`, {
              method: 'DELETE',
              headers: {
                  'Content-Type': 'application/json',
              },
              dataType: 'json'
            })
            .then(result => {
              $('#curriculum-audio').val('');
            })
            .catch(error)
          }
        });
      }
    }

    filePond.setOptions({
      onaddfilestart: (file) => {
        this.isLoadingFiles(filePond);
      },
      onprocessfiles: (error, file) => {
        this.isLoadingFiles(filePond);
      },
      onprocessfileabort: (file) => {
        this.isLoadingFiles(filePond);
      },
      onremovefile: (error, file) => {
        if(audioDataSet){
          // Remove blobs that are uploaded through direct-upload before saving in signedid in db
          fetch(`/remove_blob/${audioDataSet.audioSignedId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            dataType: 'json'
          })
          .then(result => {
            $('#curriculum-audio').val('');
          })
          .catch(error)
        }
        this.enableSubmit();
      },
      server: {
        revert: (uniqueFileId, load, error) => {
          // Remove blob's that are uploaded through direct-upload after signedid is persisten in db
          fetch(`/remove_blob/${uniqueFileId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            dataType: 'json'
          })
          .then(result => {
            $('#curriculum-audio').val('');
            load();
          })
          .catch(error)
        },
        process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {

          var url = ele.dataset.directUploadUrl;
          var targetEleName = ele.name;

          const upload = new DirectUpload(file, url);
          upload.create((error, blob) => {
            if (error) {
              console.log(error)
              this.disableSubmit();
            } else {
              $('#curriculum-audio').val(blob.signed_id);
              load(blob.signed_id);
              this.enableSubmit();
            }
          })

          return {
            abort: () => {
              abort();
            },
          };
        },
      }
    })
  }
}