import { Controller } from 'stimulus';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['edit', 'save', 'servingTotal', 'servingControl', 'increase', 'decrease', 'update']
  connect() {
    console.log('Meal Ingredients Controller');
  }

  increaseQuantity(event){
    console.log('increase');
    event.preventDefault();
    const that = this;
    const target = this.increaseTarget;
    Rails.ajax({
        type: "post",
        url: target.dataset.url,
        beforeSend(xhr, options) {
          xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
          // Workaround: add options.data late to avoid Content-Type header to already being set in stone
          // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
          return true
        },
        dataType: 'json',
        success: function(data, status, xhr) {
          that.editQuantity();
        }
      })
  }

  decreaseQuantity(event){
    console.log('decrease');
    event.preventDefault();
    const that = this;
    const target = this.decreaseTarget;
    Rails.ajax({
        type: "post",
        url: target.dataset.url,
        beforeSend(xhr, options) {
          xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
          // Workaround: add options.data late to avoid Content-Type header to already being set in stone
          // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
          return true
        },
        dataType: 'json',
        success: function(data, status, xhr) {
          that.editQuantity();
        }
      })
  }

  updateQuantity(e) {
    // console.log('e', e.key, e.keyCode, e.key === undefined, e.target);
    e.preventDefault();
    e.target.focus();
    if (e.key === 'Enter' || e.keyCode === 13 || e.key === undefined) {
      const that = this;
      const target = this.updateTarget;
      const val = $(this.updateTarget).val();
      const data = { servings: val};
      // console.log('enter pressed', link);
      if(val.length > 0){
        $('#loader').removeClass('hidden');
        Rails.ajax({
          type: "post",
          url: target.dataset.url,
          beforeSend(xhr, options) {
            xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
            // Workaround: add options.data late to avoid Content-Type header to already being set in stone
            // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
            options.data = JSON.stringify(data)
            return true
          },
          dataType: 'json',
          success: function(data, status, xhr) {
            that.editQuantity();
          }
        })
      }
    }
  }
  editQuantity(){
    const ingredientTotalHiddenClasses = "opacity-0 pointer-events-none";
    $('.serving-control:visible').hide();
    $('.serving-total.opacity-0').removeClass(ingredientTotalHiddenClasses);
    $(this.servingTotalTarget).addClass(ingredientTotalHiddenClasses);
    $(this.servingControlTarget).show();
  }

  saveQuantity(){
    const ingredientTotalHiddenClasses = "opacity-0 pointer-events-none";
    $(this.servingControlTarget).fadeOut(100);
    $(this.servingTotalTarget).removeClass(ingredientTotalHiddenClasses);
  }
}
