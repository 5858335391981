import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['group', 'cohort', 'selectCohorts', 'selectGroups', 'coach', 'transferBtnWrapper', 'ownershipTransferModal', 'confirmTransferBtn'];
  static values = {
    transferUrl: String
  };
  connect() {
    // console.log('ownership connected');
    this.csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    this.cohort_ids = [];
    this.group_ids = [];
  }

  toggleGroup(e){
    // console.log('toggle group', e.target)
    const index = this.group_ids.indexOf(parseInt(e.target.value));
    if(e.target.checked){
      if(index == -1){
        this.group_ids.push(parseInt(e.target.value));
      }
    } else {
      if(index > -1){
        this.group_ids.splice(index, 1)
      }
    }
    // console.log('this.group_ids', this.group_ids)
    this.showTransferBtn();
  }

  selectGroups(){
    this.group_ids = []
    this.groupTargets.forEach((target) => {
      this.group_ids.push(parseInt(target.value));
      if(!target.checked){
        // console.log('target.value', target.value);
        target.checked = true;
      }
    })
    // console.log('this.group_ids', this.group_ids)
    this.showTransferBtn();
  }

  toggleCohort(e){
    const index = this.cohort_ids.indexOf(parseInt(e.target.value));
    // console.log('toggle cohort', this.cohort_ids, index);
    if(e.target.checked){
      if(index == -1){
        this.cohort_ids.push(parseInt(e.target.value));
      }
    } else {
      if(index > -1){
        this.cohort_ids.splice(index, 1)
      }
    }
    // console.log('this.cohort_ids', this.cohort_ids)
    this.showTransferBtn();
  }

  selectCohorts(){
    this.cohort_ids = [];
    this.cohortTargets.forEach((target) => {
      this.cohort_ids.push(parseInt(target.value));
      if(!target.checked){
        // console.log('target.value', target.value);
        target.checked = true;
      }
    })
    // console.log('this.cohort_ids', this.cohort_ids)
    this.showTransferBtn();
  }

  showTransferBtn(){
    if(this.cohort_ids.length > 0 || this.group_ids.length > 0) {
      this.transferBtnWrapperTarget.classList.remove('hidden');
    } else {
      this.transferBtnWrapperTarget.classList.add('hidden');
    }
  }

  showTransferModal(){
    // this.ownershipTransferModalTarget.classList.remove('hidden');
   window.toggleModal('transfer_ownership_modal');
  }

  confirmTransfer(){
    const that = this;
    const coach_id = this.coachTarget.value;
    if(coach_id.length > 0){
      $('#loader').removeClass('hidden');
      // console.log('that.url', that.transferUrlValue);
      fetch(that.transferUrlValue, {
        method: 'post',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-CSRF-Token': that.csrf
        },
        body: JSON.stringify({
          coach_id: coach_id,
          cohort_ids: that.cohort_ids,
          group_ids: that.group_ids
        })
      })
      .then((response) => {
        window.location.reload();
      })
    }
  }
}
