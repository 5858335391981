import { Controller } from 'stimulus';
import Rails from "@rails/ujs";
import autosize from 'autosize';
import striptags  from 'striptags';
export default class extends Controller {
  static targets = ['subcomment', 'textarea', 'accessoryMenuBtn', 'accessoryMenu', 'postHead', 'sendBtn', 'keyInstructions']
  connect() {
    if(this.hasTextareaTarget) {
      autosize(this.textareaTarget);
    }
  }
  publish(event) {
    const that = this;
    const target = event.target;
    let btnDisplay = getComputedStyle(target.nextSibling).getPropertyValue('display');
    if(btnDisplay == 'block' || event.key != 'Enter') {
      // console.log('btn', btnDisplay, event.key);
      return
    }
    const subcommentContainer = this.subcommentTarget;
    let existingContent = '';
    // console.log('key', event.key, event.target, window.selectableMembersTribute.isActive);
    if(target.innerText.trim().length > 0 && !event.shiftKey && !window.selectableMembersTribute.isActive) {
      $('#loader').removeClass('hidden');
      const data = {
        'comment': {
          'body': striptags(target.innerHTML, ['span'], '\n')
        }
      }
      Rails.ajax({
        type: "post",
        url: target.dataset.url,
        beforeSend(xhr, options) {
          xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
          // Workaround: add options.data late to avoid Content-Type header to already being set in stone
          // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
          options.data = JSON.stringify(data)
          return true
        },
        dataType: 'json',
        success: function(data, status, xhr) {
          existingContent = subcommentContainer.innerHTML;
          // console.log('elem', elem, existingContent);
          subcommentContainer.innerHTML = existingContent + xhr.responseText;
          target.innerHTML = '';
          that.resize();
          $('#loader').addClass('hidden');
        },
        error: function() {
          $('#loader').addClass('hidden');
          alert("Your message couldn't be sent, please check your connection and try again later.");
          return;
        }
      })
      target.blur();
    }
  }

  send(event) {
    event.preventDefault();
    const that = this;
    const target = this.textareaTarget;
    const subcommentContainer = this.subcommentTarget;
    let existingContent = '';
    // console.log('key', event.key, event.target, window.selectableMembersTribute.isActive);
    if(target.innerText.trim().length > 0) {
      $(this.keyInstructionsTarget).slideUp(200);
      that.sendBtnTarget.classList.add("opacity-0","scale-0","pointer-events-none");
      const data = {
        'comment': {
          'body': striptags(target.innerHTML, ['span'], '\n')
        }
      }
      $('#loader').removeClass('hidden');
      Rails.ajax({
        type: "post",
        url: target.dataset.url,
        beforeSend(xhr, options) {
          xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
          // Workaround: add options.data late to avoid Content-Type header to already being set in stone
          // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
          options.data = JSON.stringify(data)
          return true
        },
        dataType: 'json',
        success: function(data, status, xhr) {
          existingContent = subcommentContainer.innerHTML;
          // console.log('elem', elem, existingContent);
          subcommentContainer.innerHTML = existingContent + xhr.responseText;
          target.innerHTML = '';
          that.resize();
          $('#loader').addClass('hidden');
        },
        error: function() {
          $('#loader').addClass('hidden');
          alert("Your message couldn't be sent, please check your connection and try again later.");
          return;
        }
      })
      target.focus();
      target.classList.add('caret-transparent');
      target.classList.remove('caret-gray-900');
      target.blur();
    }
  }

  hideActions(event){
    $(this.keyInstructionsTarget).slideUp(200);
    const that = this;
    setTimeout(function(){
      that.sendBtnTarget.classList.add("opacity-0","scale-0","pointer-events-none");
    }, 100)
    return
  }

  focus(event){
    event.target.classList.remove('caret-transparent')
    event.target.classList.add('caret-gray-900');
    $(this.keyInstructionsTarget).slideDown(200);
    this.sendBtnTarget.classList.remove("opacity-0","scale-0","pointer-events-none");
  }

  resize(){
    this.setStyles({height: 'auto'}) // Force textarea to shrink back to its minimum height
    // this.setStyles({height: this.scrollHeight})
  }

  get scrollHeight() {
    // console.log('text area height scroll', this.textareaTarget.scrollHeight);
    return this.textareaTarget.scrollHeight + 'px'
  }

  setStyles(styles) {
    // console.log('this.textareaTarget', this.textareaTarget);
    for (var property in styles) {
      this.textareaTarget.style[property] = styles[property]
    }
  }

  closeClosestAccessoryMenu(){
    $('.accessory-menu.open').removeClass('open').addClass('hidden');
  }

  applyMenu(){
    var targetEle = $(this.accessoryMenuBtnTarget).next('.accessory-menu');

    if(targetEle.hasClass('open')){
      targetEle.removeClass('open').addClass('hidden');
    }else{
      $('.accessory-menu.open').removeClass('open').addClass('hidden');
      targetEle.removeClass('hidden').addClass('open');
    }
  }
}