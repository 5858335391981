import { Controller } from 'stimulus';
import SlimSelect from 'slim-select';

export default class extends Controller {
  static targets = ['input'];
  static values = {
    eligibleMembersListUrl: String
  };
  connect() {
    this.csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    // console.log('input target on connect', this.inputTarget);
  }
  inputTargetConnected(element) {
    console.log('input target connected', this.eligibleMembersListUrlValue);
    const that = this;
    fetch(that.eligibleMembersListUrlValue, {
      method: 'get',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-CSRF-Token': that.csrf
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        console.log('result', result);
        for (let i = 0; i < result.length; i++) {
          const user = result[i];
          let option = document.createElement("option");
          option.setAttribute('value', user.id);

          let optionText = document.createTextNode(user.email);
          option.appendChild(optionText);

          that.inputTarget.appendChild(option);
        }
        new SlimSelect({
          select: that.inputTarget
        })
      })
  }
}