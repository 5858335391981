import { Controller } from "stimulus"
import * as FilePond from 'filepond';
import { DirectUpload } from "@rails/activestorage";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import CustomFilePondPluginValidateVideoLength from '../filepond_plugin_video_length.js';


FilePond.registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginFilePoster,
  FilePondPluginMediaPreview,
  FilePondPluginFileValidateSize,
  CustomFilePondPluginValidateVideoLength
);

export default class extends Controller {
  static targets = ["cohortPostMedia"]

  connect() {
    this.emtpyMediaValues();
    this.applyFilePond();
  }

  emtpyMediaValues(){
    $('#post-video').val('');
    $('#post-image').val('');
  }

  isAllowedVideoType(type){
    var videoTypes = ['video/x-ms-wmv', 'video/quicktime', 'video/mp4', 'video/x-m4v', 'video/x-msvideo', 'video/x-flv',
                      'video/mpeg2', 'video/x-m4v', 'video/3gpp', 'video/x-ms-asf']

    // If the element was not found, -1 will be returned.
    if($.inArray(type, videoTypes) !== -1){
      return true;
    }else{
      return false;
    }
  }

  enableSubmit() {
    console.log("enable submit");
    $('#post-submit-btn').removeAttr('disabled', '').removeClass('disabled').text('Publish');
  }
  disableSubmit() {
    console.log("disable submit");
    $('#post-submit-btn').attr('disabled', '').addClass('disabled').text('Hold on...');
  }

  isLoadingFiles(filePond) {
    var isFileLoading = filePond.getFiles().filter(x => x.status !== 5).length !== 0;

    if (isFileLoading) {
      this.disableSubmit();
    } else {
      this.enableSubmit();
    }
  }

  isHeic(fileType) { // check file for heic
    return fileType == 'image/heic' || fileType == 'image/heif';
  }

  applyFilePond() {
    const ele = this.cohortPostMediaTarget;
    const existingImage = document.querySelector("#existing_post_image");
    const existingVideo = document.querySelector("#existing_post_video");

    var imageDataSet;
    var videoDataSet;
    var imageUrl;
    var imageSignedId;
    var imageName;
    var imageSize;
    var imageContentType;
    var imageId;
    var imageBlobId;

    var videoUrl;
    var videoSignedId;
    var videoName;
    var videoSize;
    var videoContentType;
    var videoId;
    var videoBlobId;


    if(existingImage){
       imageDataSet = $("#existing_post_image").data();
    }else{
      imageDataSet = null;
    }

    if(existingVideo){
      videoDataSet = $("#existing_post_video").data();
    }else{
      videoDataSet = null;
    }

    const filePond = FilePond.create(ele, {
      credits: false,
      allowImageResize: true,
      acceptedFileTypes: [ 'image/jpg', 'image/png', 'image/jpeg', 'image/pjpeg', 'image/heic', 'image/heif',  'video/x-ms-wmv', 'video/quicktime', 'video/mp4',
                           'video/x-m4v', 'video/x-msvideo', 'video/x-flv', 'video/x-m4v', 'video/3gpp', 'video/x-ms-asf' ],
      fileValidateTypeLabelExpectedTypes: 'You can only upload image or video.',
      allowVideoPreview: true,
      allowAudioPreview: true,
      imagePreviewMaxHeight: 800,
      imagePreviewUpscale: true,
      allowImageCrop: false,
      maxFileSize: '800MB',
      maxFiles: 1,
      allowVideoLengthValidation: true,
      maxVideoLength: 120,
      minVideoLength: 1,
      labelTapToUndo: '',
      iconRemove: '<i class="fas fa-trash"></i>',
      stylePanelLayout: 'compact',
      styleButtonRemoveItemPosition: 'center bottom',
      styleButtonProcessItemPosition: 'center bottom',
      styleLoadIndicatorPosition: 'center bottom',
      styleProgressIndicatorPosition: 'center bottom',
      labelIdle: 'Drag & Drop your file or <span class="filepond--label-action"> Browse </span>',
      fileValidateTypeDetectType: (source, type) =>
        new Promise((resolve, reject) => {
            // test if it is heif/heic file
            if (/\.heif$/.test(source.name)) return resolve('image/heif');
            if (/\.heic$/.test(source.name)) return resolve('image/heic');

            // accept detected type
            resolve(type);
        }),
      allowImageValidateSize: true,
      imageTransformOutputMimeType: 'image/jpeg'
    });


    if (existingImage) {
      $('#feed-post-image-link').trigger('click');

      if (imageDataSet) {
        imageUrl = imageDataSet.imageUrl;
        imageSignedId = imageDataSet.imageSignedId;
        imageName = imageDataSet.imageName;
        imageSize = imageDataSet.imageSize;
        imageContentType = imageDataSet.imageContentType;
        imageId = imageDataSet.imageId;
        imageBlobId = imageDataSet.imageBlobId;

        $('#post-video').val('');
        $('#post-image').val(imageSignedId);

        filePond.setOptions({
            files: [{
                source: imageSignedId,
                options: {
                    type: 'local',
                    file: {
                        name: imageName,
                        size: imageSize,
                        type: imageContentType,
                    },
                    metadata: {
                        poster: imageUrl
                    }
                }
            }],
            onremovefile: (error, file) => {
                console.log("Removing Image")
                fetch(`/remove_blob/${imageBlobId}`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        dataType: 'json'
                    })
                    .then(result => {
                      $('#post-image').val('');
                    })
                    .catch(error)
            }
        });
      }
    }

    if (existingVideo) {
      $('#feed-post-image-link').trigger('click');

      if (videoDataSet) {
        videoUrl = videoDataSet.videoUrl;
        videoSignedId = videoDataSet.videoSignedId;
        videoName = videoDataSet.videoName;
        videoSize = videoDataSet.videoSize;
        videoContentType = videoDataSet.videoContentType;
        videoId = videoDataSet.videoId;
        videoBlobId = videoDataSet.videoBlobId;

        $('#post-image').val('');
        $('#post-video').val(videoSignedId);

        filePond.setOptions({
          files: [{
            source: videoSignedId,
            options: {
              type: 'local',
              file: {
                name: videoName,
                size: videoSize,
                type: videoContentType,
                url: videoUrl
              }
            }
          }],
          onremovefile: (error, file) => {
            // Remove blobs that are uploaded through direct-upload before saving in signedid in db
            fetch(`/remove_blob/${videoBlobId}`, {
              method: 'DELETE',
              headers: {
                  'Content-Type': 'application/json',
              },
              dataType: 'json'
            })
            .then(result => {
              $('#post-video').val('');
            })
            .catch(error)
          }
        });
      }
    }

    filePond.setOptions({
      onaddfilestart: (file) => {
        this.isLoadingFiles(filePond);
      },
      onprocessfiles: (error, file) => {
        this.isLoadingFiles(filePond);
      },
      onprocessfileabort: (file) => {
        this.isLoadingFiles(filePond);
      },
      onremovefile: (error, file) => {
        this.enableSubmit();
      },
      server: {
        revert: (uniqueFileId, load, error) => {
          // Remove blob's that are uploaded through direct-upload after signedid is persisten in db
          fetch(`/remove_blob/${uniqueFileId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            dataType: 'json'
          })
          .then(result => {
            load();
          })
          .catch(error)
        },
        process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {

          var url = ele.dataset.directUploadUrl;
          var targetEleName = ele.name;
          const upload = new DirectUpload(file, url);
          upload.create((error, blob) => {
            if (error) {
              console.log(error)
              this.disableSubmit();
            } else {
              if(this.isAllowedVideoType(file.type)){
                $('#post-video').val(blob.signed_id);
                $('#post-image').val('');
              }else{
               $('#post-image').val(blob.signed_id);
               $('#post-video').val('');
              }
              load(blob.signed_id);
              this.enableSubmit();
            }
          })

          return {
            abort: () => {
              abort();
            },
          };
        },
      }
    })
  }
}