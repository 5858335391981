import { Controller } from 'stimulus';
import Rails from "@rails/ujs";

let inputConfirmation = false;

export default class extends Controller {
    static targets = ['prev', 'next', 'form', 'weight', 'waist', 'liftreps', 'liftweight', 'profileHeightInput', 'profileWeightInput', 'profileHeightImpFt', 'profileHeightImpInch']
    connect() {
        // console.log('connect daily stats');
        // console.log('this.previousTarget', this.previousTarget);
        // console.log('this.nextTarget', this.nextTarget);
        // console.log('this.formTarget', this.formTarget);
        inputConfirmation = false;
    }

    next(event) {
        console.log("Next")
        event.preventDefault();
        event.stopImmediatePropagation();
        // console.log('navigate');
        const url = this.nextTarget.getAttribute('data-link');
        this.navigate(url);
    }

    prev(event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        // console.log('navigate');
        const url = this.prevTarget.getAttribute('data-link');
        // console.log('url', url);
        this.navigate(url);
    }

    navigate(url) {
        if (inputConfirmation) {
            this.formTarget.setAttribute('action', url);
            this.formSubmit();
        } else {
            this.renderForm(url);
        }
        inputConfirmation = false;
    }

    inputChanged() {
        if (this.validateNumber(this.weightTarget)) {
            inputConfirmation = true;
        }
    }

    formSubmit() {
        Rails.fire(this.formTarget, 'submit');
    }

    renderForm(url) {
        Rails.ajax({
            url: `${url}`,
            type: 'get',
            dataType: 'script'
        });
    }

    waistInputChanged() {
        this.validateNumber(this.waistTarget)
    }

    liftRepsInputChanged() {
        this.validateInteger(this.liftrepsTarget)
    }

    liftWeightInputChanged() {
        this.validateNumber(this.liftweightTarget)
    }

    profileHeightInputChanged() {
        this.validateNumber(this.profileHeightInputTarget)
    }

    profileWeightInputChanged() {
        this.validateNumber(this.profileWeightInputTarget)
    }

    profileHeightImpFtChanged() {
        this.validateNumber(this.profileHeightImpFtTarget)
    }

    profileHeightImpInchChanged() {
        this.validateNumber(this.profileHeightImpInchTarget)
    }

    validateNumber(ele) {
        var validNumberRegEx = new RegExp(/^\d*\.?\,?\d*$/);
        var commaEndRegEx = new RegExp(/,\s*$/);
        var dotEndRegEx = new RegExp(/.\s*$/);
        //var negitiveNumberRegEx = new RegExp(/^-?\d{2}(\.\d+)?$/);
        var lastValidValue = ele.value;

        if (validNumberRegEx.test(ele.value)) {
            lastValidValue = ele.value;
            return true
        } else {
            alert('Only numbers are permitted');

            if (validNumberRegEx.test(lastValidValue)) {
                lastValidValue = lastValidValue.replace(/[^\d*\.?\,?\d]/, '');
            }

            if (dotEndRegEx.test(lastValidValue)) {
                lastValidValue = lastValidValue.replace(/.\s*$/, '');
            }

            if (commaEndRegEx.test(lastValidValue)) {
                lastValidValue = lastValidValue.replace(/\,\s*$/, '');
            }

            ele.value = lastValidValue;
            return false
        }
    }

    validateInteger(ele) {
        var integerNumberRegEx = new RegExp(/^\d*[0-9]\d*$/);
        var decimalNumberRegEx = new RegExp(/^\d*\.?\,?\d*$/);
        var commaEndRegEx = new RegExp(/,\s*$/);
        var dotEndRegEx = new RegExp(/.\s*$/);
        var lastValidValue = ele.value;

        if (integerNumberRegEx.test(ele.value)) {
            lastValidValue = ele.value;
            return true
        } else {
            alert('Only integer numbers are permitted');

            if (integerNumberRegEx.test(lastValidValue)) {
                lastValidValue = lastValidValue.replace(/^\d*[0-9]\d*$/, '');
            }

            if (decimalNumberRegEx.test(lastValidValue)) {
                lastValidValue = lastValidValue.replace(/[^\d*\.?\,?\d]/, '');
            }

            if (dotEndRegEx.test(lastValidValue)) {
                lastValidValue = lastValidValue.replace(/.\s*$/, '');
            }

            if (commaEndRegEx.test(lastValidValue)) {
                lastValidValue = lastValidValue.replace(/\,\s*$/, '');
            }

            ele.value = lastValidValue;
            return false
        }
    }
}