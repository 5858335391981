import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['morning', 'evening']
  connect() {
    // console.log('reminders added');
    this.refreshIntervalValue = 60000;
    this.morningCompleted = this.morningTarget.getAttribute('data-completed');
    this.eveningCompleted = this.eveningTarget.getAttribute('data-completed');
    if(this.morningCompleted == 'false'){
      this.morningTarget.classList.remove('hidden');
    }
    this.showEveningCta();
    if(this.eveningCompleted == 'false'){
      this.refresh();
    }
  }

  refresh() {
    this.interval = setInterval(() => {
      if(this.eveningCompleted == 'false'){
        this.showEveningCta();
      }
    }, this.refreshIntervalValue)
  }

  showEveningCta(){
    this.morningCompleted = this.morningTarget.getAttribute('data-completed');
    this.eveningCompleted = this.eveningTarget.getAttribute('data-completed');
    // console.log('this.morningCompleted', this.morningCompleted);
    if(this.morningCompleted == 'false'){
      return;
    }
    if(!this.eveningTarget.classList.contains('hidden') || this.eveningCompleted == 'true'){
      clearInterval(this.interval);
      return
    }
    if (this.eveningCompleted == 'false'){
      let currentDate = new Date();
      let hour = currentDate.getHours();
      if(hour >= 17){
        this.eveningTarget.classList.remove('hidden');
      }
    } 
  }
}