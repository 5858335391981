import { Controller } from 'stimulus';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ 'cardNumber', 'cardExpiry', 'cardCvc', 'cardNumberError', 'cardExpiryError', 'cardCvcError', 'cardErrors', 'cardForm', 'formContainer']
  static values = {
    key: String,
  }

  cardFormTargetConnected(){
    const cardForm = this.cardFormTarget;
    const that = this;
    cardForm.addEventListener('submit', function(event) {
      console.log('card form submit');
      event.preventDefault();
      $('#loader').removeClass('hidden');
      $('#card-errors, #card-number-error, #card-expiry-error, #card-cvc-error').html('').addClass('hidden');
      fetch('/profile/setup_intent', {
          method: 'post',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'X-CSRF-Token': that.csrf
          }
        })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          //console.log('intent result', result);
          if (result.error) {
            throw result;
          }
          return that.stripe
            .confirmCardSetup(result.intent.client_secret, {
              payment_method: {
                card: that.cardNumber
              }
            })
            .then(function(result) {
              // Handle result.error or result.paymentIntent
              //console.log('confirm payment result', result);
              if (result.error) {
                // Inform the customer that there was an error.
                that.showCardError(result.error.message);
                return false;
              } else{
                console.log('result setup intent', result);
                that.updateCardInfo(result.setupIntent.payment_method)
              }
            })
            .catch((error) => {
                // An error has happened. Display the failure to the user here.
                // We utilize the HTML element we created.
                //console.log('error 200', error);
                $('#loader').addClass('hidden');
                if(error.coupon_invalid){
                  alert(error.error.message);
                  $("button[type='submit']").removeAttr('disabled');
                  window.location.reload();
                  return;
                }
                that.showCardError(error.message);
                return false;
              })
        })

        // No more actions required. Provision your service for the user.
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          //console.log('error 223', error);
          $('#loader').addClass('hidden');
          if(error.coupon_invalid){
            alert(error.error.message);
            $("button[type='submit']").removeAttr('disabled');
            window.location.reload();
            return;
          }
          that.showCardError(error);
          return false;
        })
    })
  }
  connect() {
    this.csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    this.cardNumber = '';
    this.stripe = Stripe(this.keyValue);
    const that = this;
    var style = {
      base: {
        fontWeight: 600,
        fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',

        ':focus': {
          color: '#424770',
        },

        '::placeholder': {
          color: '#9BACC8',
        },

        ':focus::placeholder': {
          color: '#CFD7DF',
        },
      },
      invalid: {
        color: '#eb1c26',
        ':focus': {
          color: '#FA755A',
        },
        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    };
    var elements = this.stripe.elements({
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css?family=Quicksand',
        },
      ]
    });

    var elementClasses = {
      focus: 'focus',
      empty: 'empty',
      invalid: 'invalid',
    };

    this.cardNumber = elements.create('cardNumber', {
      style: style,
      showIcon: true,
      iconStyle: 'solid',
      placeholder: 'Card Number',
      classes: elementClasses
    });
    this.cardNumber.mount(this.cardNumberTarget);

    var cardExpiry = elements.create('cardExpiry', {
      style: style,
      placeholder: 'Expiry Date',
      classes: elementClasses,
    });
    cardExpiry.mount(this.cardExpiryTarget);

    var cardCvc = elements.create('cardCvc', {
      style: style,
      placeholder: 'CVC',
      classes: elementClasses,
    });
    cardCvc.mount(this.cardCvcTarget);

    this.cardNumber.on('change', function (event) {
      that.displayError(that.cardNumberErrorTarget, event);
    });
    cardExpiry.on('change', function (event) {
      that.displayError(that.cardExpiryErrorTarget,event);
    });
    cardCvc.on('change', function (event) {
      that.displayError(that.cardCvcErrorTarget, event);
    });
  }

  displayError(elem, event) {
    if (event.error) {
      elem.textContent = event.error.message;
      elem.classList.remove('hidden');
    } else {
      elem.textContent = '';
      elem.classList.add('hidden');
    }
  }

  updateCardInfo(paymentMethodId){
    return (
      fetch('/profile/card', {
        method: 'post',
        body: JSON.stringify({
          payment_method_id: paymentMethodId,
        }),
        headers: {
          'Content-type': 'application/json',
          'X-CSRF-Token': this.csrf
        }
      })
        // If the card is declined, display an error to the user.
        .then((result) => {
          console.log('result', result);
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })

        .then((result) => {
          return {
            paymentMethodId: paymentMethodId,
          };
        })
        // No more actions required. Provision your service for the user.
        .then(this.onCardUpdate)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          this.showCardError(error);
        })
    );
  }

  onCardUpdate() {
    window.location = '/profile/card'
  }

  showCardError(message){
    //console.log('last card error', message);
    $('#loader').addClass('hidden');
    $("button[type='submit']").removeAttr('disabled');
    this.cardErrorsTarget.classList.remove('hidden');
    this.cardErrorsTarget.innerHTML = message;
  }
}