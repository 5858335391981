import { Controller } from 'stimulus';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ 'cardNumber', 'cardExpiry', 'cardCvc', 'cardNumberError', 'cardExpiryError', 'cardCvcError', 'cardErrors', 'depositFormContainer', 'priceContainer', 'coursePrice', 'totalPrice', 'paymentForm']
  static values = {
    key: String,
    amount: String,
    intentUrl: String
  }

  connect() {
    console.log('deposit connect');
    this.csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  }

  cardNumberTargetConnected(element){
    console.log('card number connect');
    this.cardNumber = '';
    this.stripe = Stripe(this.keyValue);
    const that = this;
    var style = {
      base: {
        fontWeight: 600,
        fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',

        ':focus': {
          color: '#424770',
        },

        '::placeholder': {
          color: '#9BACC8',
        },

        ':focus::placeholder': {
          color: '#CFD7DF',
        },
      },
      invalid: {
        color: '#eb1c26',
        ':focus': {
          color: '#FA755A',
        },
        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    };
    var elements = this.stripe.elements({
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css?family=Quicksand',
        },
      ]
    });

    var elementClasses = {
      focus: 'focus',
      empty: 'empty',
      invalid: 'invalid',
    };

    this.cardNumber = elements.create('cardNumber', {
      style: style,
      showIcon: true,
      iconStyle: 'solid',
      placeholder: 'Card Number',
      classes: elementClasses
    });
    this.cardNumber.mount(this.cardNumberTarget);

    var cardExpiry = elements.create('cardExpiry', {
      style: style,
      placeholder: 'Expiry Date',
      classes: elementClasses,
    });
    cardExpiry.mount(this.cardExpiryTarget);

    var cardCvc = elements.create('cardCvc', {
      style: style,
      placeholder: 'CVC',
      classes: elementClasses,
    });
    cardCvc.mount(this.cardCvcTarget);

    this.cardNumber.on('change', function (event) {
      that.displayError(that.cardNumberErrorTarget, event);
    });
    cardExpiry.on('change', function (event) {
      that.displayError(that.cardExpiryErrorTarget,event);
    });
    cardCvc.on('change', function (event) {
      that.displayError(that.cardCvcErrorTarget, event);
    });
  }

  displayError(elem, event) {
    if (event.error) {
      elem.textContent = event.error.message;
      elem.classList.remove('hidden');
    } else {
      elem.textContent = '';
      elem.classList.add('hidden');
    }
  }

  showCardError(message){
    //console.log('last card error', message);
    $('#loader').addClass('hidden');
    $("button[type='submit']").removeAttr('disabled');
    this.cardErrorsTarget.classList.remove('hidden');
    this.cardErrorsTarget.innerHTML = message;
  }

  coursePriceTargetConnected(element){
    // console.log('tis.amountVaue', this.amountValue, element);
    element.textContent = this.amountValue
  }

  totalPriceTargetConnected(element){
    // console.log('tis.amountVaue', this.amountValue, element);
    element.textContent = this.amountValue
  }

  paymentFormTargetConnected(element){
    const form = this.paymentFormTarget;
    const that = this;
    form.addEventListener('submit', function(event) {
      event.preventDefault();
      $('#loader').removeClass('hidden');
      $('#card-errors, #card-number-error, #card-expiry-error, #card-cvc-error').html('').addClass('hidden');
      fetch(that.intentUrlValue, {
        method: 'post',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-CSRF-Token': that.csrf
        }
      })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        // console.log('intent result', result);
        if (result.error) {
          throw result;
        }
        return that.stripe
          .confirmCardPayment(result.intent.client_secret, {
            payment_method: {
              card: that.cardNumber
            }
          })
          .then(function(result) {
            // Handle result.error or result.paymentIntent
            console.log('result', result);
            if (result.error) {
              // Inform the customer that there was an error.
              that.showCardError(result.error.message);
              return false;
            } else {
              that.processDeposit(result.paymentIntent.payment_method);
              setTimeout(function(){
                window.location = '/deposit/success';
              }, 2000)
            }
          })
          .catch((error) => {
              // An error has happened. Display the failure to the user here.
              // We utilize the HTML element we created.
              console.log('error', error);
              that.showCardError(error.message);
              $('#loader').addClass('hidden');
            })
          })
    });
  }

  processDeposit(paymentMethodId){
    return (
        fetch("/deposit/process", {
          method: 'post',
          headers: {
            'Content-type': 'application/json',
            'X-CSRF-Token': this.csrf
          },
          body: JSON.stringify({
            payment_method_id: paymentMethodId,
          }),
        })
          .then((response) => {
            return response.json();
          })
          // If the card is declined, display an error to the user.
          .then((result) => {
            console.log('result', result);
            if (result.error) {
              // The card had an error when trying to attach it to a customer.
              throw result;
            }
            return result;
          })

          // Some payment methods require a customer to be on session
          // to complete the payment process. Check the status of the
          // payment intent to handle these actions.
          // If attaching this card to a Customer object succeeds,
          // but attempts to charge the customer fail, you
          // get a requires_payment_method error.
          // No more actions required. Provision your service for the user.
          .catch((error) => {
            // An error has happened. Display the failure to the user here.
            // We utilize the HTML element we created.
            console.log('error', error);
            that.showCardError(error);
          })
      );
  }
}