import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['category', 'item', 'source', 'ingredient', 'preference']
  
  connect() {
    // console.log('filter connected')
    this.selectedCategory = '';
    this.selectedPreference = '';
    this.selectedItemClass = ["bg-blue-500", "hover:bg-blue-500", "text-white", "font-medium"];
  }

  selectCategory(e) {
    e.preventDefault();
    this.selectedCategory = this.categoryTarget.value;
    this.toggleItemsVisibility();
    this.filter();
  }

  selectPreference(e) {
    e.preventDefault();
    const preference = this.preferenceTarget.value;
    if(this.selectedPreference == preference){
      this.selectedPreference = '';
    } else {
      this.selectedPreference = preference;
    }
    this.toggleItemsVisibility();
    this.filter();
  }

  toggleItemsVisibility() {
    this.itemTargets.forEach((el, i) => {
      el.classList.remove(...['hidden', 'hidden-ingredient']);
    })
    if(this.selectedCategory.length > 0 && this.selectedPreference.length > 0){
      this.itemTargets.forEach((el, i) => {
        const category = el.getAttribute('data-category');
        const itemPreference = el.getAttribute(`data-${this.selectedPreference}`);
        // console.log('itemPreference', itemPreference, itemPreference !== 'true');
        el.classList.toggle('hidden', category !== this.selectedCategory || itemPreference !== 'true');
      })
    } else if(this.selectedCategory.length > 0){
      this.itemTargets.forEach((el, i) => {
        const category = el.getAttribute('data-category');
        el.classList.toggle('hidden', category !== this.selectedCategory);
      })
    } else if(this.selectedPreference.length > 0){
      this.itemTargets.forEach((el, i) => {
        const itemPreference = el.getAttribute(`data-${this.selectedPreference}`);
        el.classList.toggle('hidden', itemPreference !== 'true');
      })
    }
  }

  filter() {
    let lowerCaseFilterTerm = this.sourceTarget.value.trim().toLowerCase();
    if(lowerCaseFilterTerm.length > 0){
      this.itemTargets.forEach((el, i) => {
        el.classList.remove('hidden-ingredient');
        let filterableKey =  el.getAttribute("data-filter-key");
        let category = el.getAttribute("data-category");
        let preference = el.getAttribute(`data-${this.selectedPreference}`);
        el.classList.remove(...this.selectedItemClass);
        this.ingredientTarget.value = '';
        if((this.selectedCategory.length === 0 || this.selectedCategory == category) && (this.selectedPreference.length == 0 || preference == 'true')){
          if(!filterableKey.includes(lowerCaseFilterTerm)){
            el.classList.add('hidden-ingredient');
          } else {
            el.classList.remove('hidden-ingredient');
          }
        }
      })
    } else {
      this.itemTargets.forEach((el, i) => {
        el.classList.remove('hidden-ingredient');
      })
    }
  }

  selectIngredient(e) {
    this.itemTargets.forEach((el, i) => {
      el.classList.remove(...this.selectedItemClass);
    })
    e.target.classList.add(...this.selectedItemClass);
    // this.sourceTarget.value = e.target.innerText;
    this.ingredientTarget.value = e.target.getAttribute('data-id');
  }
}