document.addEventListener("turbo:load", function(e) {
  $(document).on('change', '#search_type', function(){
    console.log('search type change')
    $('#search_category').val('');
    $(this).closest('form').submit();
  })
  $(document).on('change', '#search_type, #search_category, #search_order', function(){
    console.log('search type change')
    $(this).closest('form').submit();
  })
})