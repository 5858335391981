import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['description']
  connect() {
  }

  modalDescription(e){
    e.preventDefault();
    const phaseId = $(this.descriptionTarget).data().phaseId;
    toggleModal(`phase-description-${phaseId}`);
  }
}