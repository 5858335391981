import { Controller } from "stimulus"
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-quality-levels'
import 'videojs-hls-quality-selector'
// import '@videojs/http-streaming'



// export default class extends Controller {
//   static targets = ['player']
//   connect() {}
//   initialize() {
//     let videoPlayer = videojs(this.playerTarget, {
//       // controls: true,
//       // playbackRates: [0.5, 1, 2],
//       // autoplay: false,
//       // fluid: true
//     })
//     // videoPlayer.addClass('video-js')
//     // videoPlayer.addClass('vjs-big-play-centered')
//     //videoPlayer.hlsQualitySelector({ displayCurrentQuality: true })
//   }
//   disconnect() {}
// }


export default class extends Controller {
  static targets = ['player']
  connect() {
    if(this.playerTarget.dataset.videoProcessed == 'true'){
      const postId = this.playerTarget.dataset.postId;
      $('.post-links-'+ postId).removeClass('disabled');
    }
  }

  initialize() {
    let videoPlayer = videojs(this.playerTarget, {
      crossorigin: "anonymous"
    })
  }

  disconnect() {}
}
