import { Controller } from 'stimulus';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['term']
  connect() {
    console.log('search called');
  }

  navigate(event){
    // console.log('event', event.target, event.target.textContent);
    window.location = `/search?search_term=${event.target.textContent}`
  }

  suggest(event) {
    console.log('keyup', this.termTarget.value, event.key);
    const searchTerm = this.termTarget.value;
    if(event.key == 'Enter' && searchTerm.length >= 1){
      // console.log('ENter key pressed');
      window.location = `/search?search_term=${searchTerm}`;
      return;
    }
    const resultsElem = document.getElementById('sidebar-search-results');
    const resultItemsElem = document.getElementById('sidebar-search-result-items');
    if(searchTerm && searchTerm.length >= 2){
      Rails.ajax({
        url: `/search/suggest?search_term=${searchTerm}`,
        type: 'get',
        dataType: 'script',
        success: function(data){
          console.log(data);
          if(data.length > 0){
            let resultStr = '';
            data.forEach((item, i) => {
              resultStr += `<div class='px-3 py-2 text-sm hover:bg-blue-100 truncate cursor-pointer' data-action='click->search#navigate'>${item}</div>`
            })
            resultItemsElem.innerHTML = resultStr;
            resultsElem.classList.remove('hidden');
          } else {
            resultsElem.classList.add('hidden');
          }
        }
      });
    } else {
      resultsElem.classList.add('hidden');
    }
  }
}