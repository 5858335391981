import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['accessoryMenuBtn', 'accessoryMenu']
  connect() {
    console.log('Accessory Menu Js')
  }

  closeClosestAccessoryMenu(){
    $('.accessory-menu.open').removeClass('open').addClass('hidden');
  }
    
  applyMenu(){
    var targetEle = $(this.accessoryMenuBtnTarget).next('.accessory-menu');

    if(targetEle.hasClass('open')){
      targetEle.removeClass('open').addClass('hidden');
    }else{
      $('.accessory-menu.open').removeClass('open').addClass('hidden');
      targetEle.removeClass('hidden').addClass('open');
    }
  }
}
