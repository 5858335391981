import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // $(this.singleChoiceSelectTarget).on('select2:select', function () {
    //   let event = new Event('change', { bubbles: true }) // fire a native event
    //   this.dispatchEvent(event);
    // });
    
    $('.date').flatpickr({});
    $('.select-two').selectize();
    $('.select-multiple').selectize({
      maxItems: 2,
      placeholder: 'None'
    });
  }
}